.component-container1 {
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
 
.component-container2 {
  flex: 0 0 auto;
  width: 114px;
  height: 114px;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
  border-color: #121212;
  border-style: solid;
  border-width: 3px;
  border-radius: var(--dl-radius-radius-round);
  justify-content: center;
  background-color: #dbf14e;
}
 
.component-image {
  width: 90px;
  height: 90px;
  object-fit: cover;
}
 
.component-text {
  color: var(--dl-color-primary-300);
  font-size: 30px;
  font-style: normal;
  font-family: Chillax;
  font-weight: 500;
  padding-top: 20px;
  padding-bottom: 20px;
}
 
@media(max-width: 767px) {
  .component-container2 {
    width: 70px;
    height: 70px;
    margin-left: var(--dl-space-space-unit);
    border-width: 2px;
  }
  .component-image {
    width: 100%;
    height: auto;
  }
  .component-text {
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
}
 
@media(max-width: 479px) {
  .component-container2 {
    width: 60px;
    height: 60px;
    margin-left: 30px;
    border-width: 1px;
  }
  .component-image {
    width: 47px;
    height: 47px;
  }
  .component-text {
    font-size: 24px;
  }
}
