.home-container10 {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
 
.home-header {
  flex: 0 0 auto;
  width: 100%;
  height: 89px;
  display: none;
  position: relative;
  align-items: flex-start;
  background-color: #F2F4CC;
}
 
.home-text10 {
  color: rgb(18, 18, 18);
  height: auto;
  font-size: 48px;
  align-self: auto;
  font-style: Medium;
  margin-top: var(--dl-space-space-halfunit);
  text-align: left;
  font-family: Chillax;
  font-weight: 500;
  line-height: 80px;
  margin-left: var(--dl-space-space-sixunits);
  font-stretch: normal;
  margin-right: 0;
  padding-left: 50px;
  margin-bottom: 0;
  text-decoration: none;
}
 
.home-container11 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
}
 
.home-image1 {
  top: 0px;
  left: 0px;
  width: 100px;
  position: absolute;
  object-fit: cover;
}
 
.home-container12 {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 100;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("/external/swimming%20pool_hero-1500w.png");
  background-repeat: no-repeat;
  background-position: bottom;
}
 
.home-container13 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-text11 {
  color: var(--dl-color-primary-100);
  width: 100%;
  font-size: 72px;
  align-self: center;
  text-align: center;
  font-family: Chillax;
  font-weight: 500;
  padding-top: 180px;
  padding-bottom: var(--dl-space-space-threeunits);
  text-transform: none;
  text-decoration: none;
}
 
.home-text15 {
  color: var(--dl-color-primary-100);
  width: 100%;
  font-size: 42px;
  text-align: center;
  font-family: General sans;
  padding-bottom: var(--dl-space-space-sixunits);
}
 
.home-button {
  color: var(--dl-color-primary-100);
  padding: var(--dl-space-space-oneandhalfunits);
  font-size: 36px;
  align-self: center;
  transition: 0.3s;
  font-family: Chillax;
  font-weight: 500;
  border-color: #121212;
  border-width: 3px;
  border-radius: 100px;
  margin-bottom: var(--dl-space-space-sixunits);
  text-transform: none;
  text-decoration: none;
  background-color: rgb(219, 241, 78);
}
 
.home-button:hover {
  background-color: #b7b991;
}
 
.home-button2 {
  display: none;
  align-self: center;
  border-width: 3px;
  border-radius: 100px;
  margin-bottom: 120px;
  background-color: #dbf14e;
}
 
.home-container14 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
 
.home-container15 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.home-text16 {
  color: var(--dl-color-primary-300);
  font-size: 48px;
  font-style: normal;
  margin-top: var(--dl-space-space-fourunits);
  font-family: Chillax;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
 
.home-text17 {
  color: var(--dl-color-primary-100);
  font-size: 48px;
  font-family: Chillax;
  font-weight: 400;
  text-transform: capitalize;
}
 
.home-container16 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
 
.home-container17 {
  flex: 0 0 auto;
  width: 50%;
  height: 100px;
  display: flex;
  align-items: flex-start;
}
 
.home-container18 {
  flex: 1;
  width: 50%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
 
.home-text20 {
  color: var(--dl-color-primary-100);
  width: 70%;
  font-size: 18px;
  margin-top: var(--dl-space-space-oneandhalfunits);
  font-family: General sans;
  line-height: 1.6;
  margin-bottom: var(--dl-space-space-sixunits);
}
 
.home-container19 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  padding-top: 0px;
  padding-left: 8%;
  padding-right: 8%;
  padding-bottom: 0px;
  justify-content: space-between;
}
 
.home-container20 {
  flex: 1;
  width: 100%;
  display: flex;
  position: relative;
  align-items: stretch;
  margin-right: var(--dl-space-space-fourunits);
  flex-direction: column;
}
 
.home-infobody1 {
  color: var(--dl-color-primary-100);
  width: 90%;
  font-size: 18px;
  font-family: General sans;
  font-weight: 400;
  line-height: 1.6;
}
 
.home-text21 {
  font-style: normal;
}
 
.home-text23 {
  font-style: normal;
}
 
.home-text25 {
  font-style: normal;
}
 
.home-text29 {
  font-style: normal;
}
 
.home-container21 {
  flex: 1;
  width: 100%;
  display: flex;
  position: relative;
  margin-right: var(--dl-space-space-fourunits);
  flex-direction: column;
  justify-content: space-between;
}
 
.home-infobody2 {
  color: var(--dl-color-primary-100);
  width: 90%;
  font-size: 18px;
  font-family: General sans;
  font-weight: 400;
  line-height: 1.6;
}
 
.home-text30 {
  font-style: normal;
}
 
.home-text31 {
  font-style: normal;
}
 
.home-text32 {
  font-style: normal;
}
 
.home-text33 {
  font-weight: 600;
}
 
.home-container22 {
  flex: 1;
  width: 100%;
  display: flex;
  position: relative;
  margin-right: 0px;
  flex-direction: column;
  justify-content: space-between;
}
 
.home-infobody3 {
  color: var(--dl-color-primary-100);
  width: 90%;
  font-size: 18px;
  font-family: General sans;
  font-weight: 400;
  line-height: 1.6;
}
 
.home-text34 {
  font-style: normal;
}
 
.home-text35 {
  font-weight: 600;
}
 
.home-container23 {
  flex: 0 0 auto;
  width: 100%;
  height: 55px;
  display: flex;
  margin-top: var(--dl-space-space-sixunits);
  align-items: flex-start;
  background-size: cover;
  background-image: url("/vector%206-200h.png");
}
 
.home-container24 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
 
.home-container25 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.home-text36 {
  color: var(--dl-color-primary-300);
  font-size: 48px;
  font-style: normal;
  margin-top: var(--dl-space-space-sixunits);
  font-family: Chillax;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
 
.home-membership-info-qa {
  width: auto;
  height: 100%;
  display: flex;
  margin-top: var(--dl-space-space-fourunits);
  align-items: flex-start;
  margin-left: 0%;
  margin-right: 0%;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: column;
}
 
.home-who-can-become-a-member {
  flex: 1;
  width: auto;
  display: flex;
  align-items: flex-start;
  padding-left: 16.6%;
  padding-right: 16.6%;
}
 
.home-image2 {
  width: 48px;
  height: auto;
  object-fit: cover;
}
 
.home-container26 {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-sixunits);
  flex-direction: column;
  justify-content: flex-start;
}
 
.home-text37 {
  color: #0d2c54;
  width: 100%;
  font-style: normal;
  margin-top: 14px;
}
 
.home-text38 {
  width: 72%;
  font-size: 18px;
  align-self: flex-start;
  font-family: General sans;
  line-height: 1.6;
  padding-top: var(--dl-space-space-unit);
}
 
.home-membership-types1 {
  flex: 1;
  width: auto;
  display: flex;
  align-items: flex-start;
  margin-left: 0%;
  margin-right: 0%;
  padding-left: 16.6%;
  margin-bottom: 0px;
  padding-right: 16.6%;
}
 
.home-image3 {
  width: 48px;
  object-fit: cover;
}
 
.home-container27 {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-sixunits);
  flex-direction: column;
  justify-content: flex-start;
}
 
.home-text39 {
  color: rgb(13, 44, 84);
  width: 100%;
  font-style: normal;
  margin-top: 14px;
}
 
.home-text40 {
  width: 72%;
  height: 100%;
  font-size: 18px;
  align-self: flex-start;
  font-family: General sans;
  line-height: 1.6;
  padding-top: var(--dl-space-space-unit);
}
 
.home-text46 {
  font-style: normal;
  font-weight: 600;
}
 
.home-text48 {
  font-style: normal;
  font-weight: 400;
}
 
.home-membership-types2 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  margin-left: 0%;
  margin-right: 0%;
  padding-left: 16.6%;
  margin-bottom: 0px;
  padding-right: 16.6%;
}
 
.home-image4 {
  width: 48px;
  object-fit: cover;
}
 
.home-container28 {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-sixunits);
  flex-direction: column;
  justify-content: flex-start;
}
 
.home-text49 {
  color: rgb(13, 44, 84);
  width: 100%;
  font-style: normal;
  margin-top: 14px;
}
 
.home-text50 {
  width: 72%;
  height: 100%;
  font-size: 18px;
  align-self: flex-start;
  font-family: General sans;
  line-height: 1.6;
  padding-top: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-twounits);
}
 
.home-text52 {
  font-style: normal;
  font-weight: 600;
}
 
.home-container29 {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
}
 
.home-container30 {
  flex: 1;
  width: auto;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-sixunits);
  flex-direction: column;
}
 
.home-text53 {
  width: 100%;
  font-size: 18px;
  font-family: General sans;
  line-height: 1.6;
  margin-right: 0px;
}
 
.home-text54 {
  font-style: normal;
  font-weight: 600;
}
 
.home-container31 {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin-right: 0px;
  flex-direction: column;
}
 
.home-text70 {
  font-size: 18px;
  margin-top: 0px;
  font-family: General sans;
  line-height: 1.6;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
}
 
.home-text71 {
  font-style: normal;
  font-weight: 600;
}
 
.home-container32 {
  flex: 0 0 auto;
  width: 100%;
  height: 126px;
  display: flex;
  position: relative;
  align-items: center;
  background-size: cover;
  justify-content: flex-start;
  background-image: url("/external/footer-200h.png");
}
 
.home-text74 {
  top: var(--dl-space-space-fiveunits);
  left: var(--dl-space-space-fourunits);
  color: var(--dl-color-primary-100);
  position: absolute;
  align-self: center;
  padding-left: 0%;
}
 
@media(max-width: 1200px) {
  .home-who-can-become-a-member {
    padding-right: 16.6%;
  }
  .home-membership-types1 {
    padding-right: 16.6%;
  }
  .home-membership-types2 {
    padding-left: 16.6%;
    padding-right: 16.6%;
  }
}
 
@media(max-width: 991px) {
  .home-container17 {
    flex: 1;
  }
  .home-container18 {
    flex: 2;
  }
  .home-text20 {
    width: 75%;
  }
  .home-infobody1 {
    width: auto;
    font-size: 16px;
  }
  .home-infobody2 {
    width: auto;
  }
  .home-infobody3 {
    width: auto;
    font-size: 16px;
  }
  .home-text38 {
    width: 84%;
  }
  .home-text40 {
    width: 84%;
    font-size: 18px;
    font-family: General sans;
    line-height: 1.6;
  }
  .home-text46 {
    font-style: normal;
    font-weight: 600;
  }
  .home-text48 {
    font-style: normal;
    font-weight: 400;
  }
  .home-text50 {
    width: 84%;
  }
}
 
@media(max-width: 767px) {
  .home-text11 {
    font-size: 48px;
    padding-top: var(--dl-space-space-sixunits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-text15 {
    font-size: 24px;
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-button {
    color: var(--dl-color-gray-black);
    padding: var(--dl-space-space-unit);
    font-size: 20px;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .home-container17 {
    display: none;
  }
  .home-container18 {
    flex: 3;
    align-self: center;
    justify-content: center;
  }
  .home-text20 {
    width: 80%;
    font-size: 18px;
    margin-bottom: var(--dl-space-space-fiveunits);
  }
  .home-container19 {
    padding-left: 8.3%;
    padding-right: 8.3%;
    flex-direction: column;
  }
  .home-container20 {
    margin-right: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-fiveunits);
  }
  .home-infobody1 {
    color: var(--dl-color-primary-100);
    font-size: 18px;
    font-family: General sans;
    font-weight: 400;
    line-height: 1.6;
  }
  .home-text21 {
    font-style: normal;
  }
  .home-text22 {
    font-weight: 600;
  }
  .home-text23 {
    font-style: normal;
  }
  .home-text24 {
    font-style: normal;
  }
  .home-text25 {
    font-style: normal;
  }
  .home-text26 {
    font-weight: 600;
  }
  .home-text27 {
    font-weight: 400;
  }
  .home-text28 {
    font-style: normal;
  }
  .home-text29 {
    font-style: normal;
  }
  .home-container21 {
    margin-right: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-fiveunits);
  }
  .home-container22 {
    margin-right: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-infobody3 {
    width: 72%;
  }
  .home-who-can-become-a-member {
    padding-left: 8%;
    padding-right: 8%;
  }
  .home-text38 {
    width: 83%;
  }
  .home-membership-types1 {
    padding-left: 8%;
    padding-right: 8%;
  }
  .home-text40 {
    width: 83%;
  }
  .home-membership-types2 {
    padding-left: 8%;
    padding-right: 8%;
  }
  .home-text50 {
    width: 83%;
  }
  .home-container29 {
    flex-direction: column;
  }
  .home-container30 {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-text53 {
    font-size: 18px;
    font-family: General sans;
    line-height: 1.6;
  }
  .home-text54 {
    font-style: normal;
    font-weight: 600;
  }
  .home-container31 {
    margin-right: var(--dl-space-space-fourunits);
  }
}
 
@media(max-width: 479px) {
  .home-text10 {
    color: rgb(18, 18, 18);
    font-size: 24px;
    font-family: Chillax;
    font-weight: 500;
    line-height: 80px;
    margin-left: 0px;
    padding-left: 30px;
    text-decoration: none;
  }
  .home-container12 {
    background-size: cover;
    background-image: url("/external/swimming%20pool_hero-1500w.png");
  }
  .home-text11 {
    font-size: 28px;
    padding-top: 90px;
    padding-bottom: 30px;
  }
  .home-text15 {
    font-size: 20px;
    font-family: General sans;
    padding-bottom: 25px;
  }
  .home-button {
    display: none;
    padding: var(--dl-space-space-halfunit);
    border-width: 1px;
    margin-bottom: 80px;
  }
  .home-button2 {
    display: flex;
    padding: var(--dl-space-space-halfunit);
    border-width: 1px;
    margin-bottom: 80px;
  }
  .home-text16 {
    font-size: 28px;
  }
  .home-text17 {
    font-size: 28px;
  }
  .home-container17 {
    display: none;
  }
  .home-container18 {
    width: 100%;
    height: auto;
    margin-bottom: 0px;
  }
  .home-text20 {
    width: auto;
    font-size: 16px;
    margin-left: 64px;
    margin-right: 64px;
  }
  .home-container19 {
    padding-left: 15%;
    padding-right: 15%;
    flex-direction: column;
  }
  .home-container20 {
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .home-infobody1 {
    width: 85%;
    font-size: 16px;
    margin-bottom: 0px;
  }
  .home-container21 {
    margin-right: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .home-infobody2 {
    width: 80%;
    font-size: 16px;
  }
  .home-container22 {
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .home-infobody3 {
    width: 85%;
    font-size: 16px;
    margin-top: 0px;
  }
  .home-container23 {
    width: 100%;
    margin-top: var(--dl-space-space-unit);
  }
  .home-text36 {
    font-size: 28px;
    margin-top: var(--dl-space-space-threeunits);
  }
  .home-membership-info-qa {
    flex: 1;
    width: auto;
    margin-top: var(--dl-space-space-twounits);
    margin-left: 0%;
    margin-right: 0%;
    padding-left: 0%;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0%;
  }
  .home-image2 {
    width: 30px;
  }
  .home-container26 {
    height: auto;
    margin-left: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .home-text37 {
    color: rgb(13, 44, 84);
    font-size: 24px;
    margin-top: 7px;
    font-family: Chillax;
  }
  .home-text38 {
    font-size: 16px;
    font-family: General sans;
    line-height: 1.6;
  }
  .home-membership-types1 {
    align-items: flex-start;
    margin-bottom: 0px;
    flex-direction: row;
    justify-content: flex-start;
  }
  .home-image3 {
    width: 30px;
  }
  .home-container27 {
    margin-left: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .home-text39 {
    width: 100%;
    font-size: 24px;
    margin-top: 7px;
  }
  .home-text40 {
    width: 80%;
    font-size: 16px;
    font-family: General sans;
    line-height: 1.6;
  }
  .home-text41 {
    font-size: 18px;
    font-family: General sans;
    font-weight: 600;
    text-transform: none;
    text-decoration: none;
  }
  .home-text42 {
    font-weight: 700;
  }
  .home-text43 {
    font-family: General sans;
  }
  .home-text46 {
    font-style: normal;
    font-weight: 600;
  }
  .home-text48 {
    font-style: normal;
    font-family: General sans;
    font-weight: 400;
  }
  .home-membership-types2 {
    align-items: flex-start;
    padding-left: 8%;
    margin-bottom: var(--dl-space-space-fourunits);
    padding-right: 8%;
    flex-direction: row;
    justify-content: flex-start;
  }
  .home-image4 {
    width: 30px;
  }
  .home-container28 {
    margin-left: var(--dl-space-space-unit);
    margin-bottom: 0px;
  }
  .home-text49 {
    width: 80%;
    font-size: 24px;
    margin-top: 7px;
  }
  .home-text50 {
    width: 80%;
    font-size: 16px;
  }
  .home-container29 {
    width: auto;
    flex-direction: column;
  }
  .home-container30 {
    width: auto;
    margin-right: 0px;
  }
  .home-text53 {
    width: 80%;
    font-size: 16px;
    font-family: General sans;
    line-height: 1.6;
    margin-right: 0px;
  }
  .home-text54 {
    font-style: normal;
    font-family: General sans;
    font-weight: 700;
  }
  .home-text70 {
    width: 80%;
    font-size: 16px;
    margin-left: 0px;
  }
  .home-container32 {
    height: 126px;
  }
  .home-text74 {
    left: var(--dl-space-space-twounits);
  }
}
