.rules-container {
    max-width: 1000px;
    margin: 2rem auto;
    padding: 0 1rem;
    font-family: system-ui, -apple-system, sans-serif;
  }
  
  .rules-header {
    background: #fff;
    border-radius: 8px;
    padding: 2rem;
    margin-bottom: 2rem;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    border: 1px solid #e5e5e5;
  }
  
  .rules-header h1 {
    text-align: center;
    color: #1a365d;
    font-size: 2rem;
    margin: 0 0 1rem 0;
  }
  
  .rules-header p {
    text-align: center;
    color: #4a5568;
    margin: 0;
  }
  
  .section-container {
    margin-bottom: 1rem;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    background: #fff;
    overflow: hidden;
  }
  
  .section-header {
    padding: 1rem;
    background: #f8f9fa;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border: none;
    width: 100%;
    text-align: left;
    transition: background-color 0.2s;
  }
  
  .section-header:hover {
    background: #f0f4f8;
  }
  
  .section-title {
    font-size: 1.125rem;
    font-weight: 600;
    color: #2c5282;
    margin: 0;
  }
  
  .section-icon {
    color: #4a5568;
  }
  
  .section-content {
    padding: 1.5rem;
    border-top: 1px solid #e5e5e5;
  }
  
  .section-content h3 {
    font-size: 1.1rem;
    font-weight: 600;
    color: #2d3748;
    margin: 0 0 0.75rem 0;
  }
  
  .section-content p {
    color: #4a5568;
    line-height: 1.6;
    margin: 0 0 1.5rem 0;
    white-space: pre-line;
  }
  
  .subsection {
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #e5e5e5;
  }
  
  .subsection:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }